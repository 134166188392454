import React, { useState, useEffect, useRef } from "react";
import PocketBase from "pocketbase";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { motion } from "framer-motion";

const transition = {
  type: "spring",
  damping: 25,
  stiffness: 120,
};

function Pictures() {
  const [images, setImages] = useState([]);
  const BASE_URL = "https://morning-bird-9561.fly.dev/api/files";

  const fetchImages = async () => {
    const client = new PocketBase("https://morning-bird-9561.fly.dev");
    const authData = await client.admins.authViaEmail(
      process.env.REACT_APP_EMAIL,
      process.env.REACT_APP_PASSWORD
    );

    const records = await client.records.getFullList(
      "pictures",
      30 /* batch size */
    );
    setImages(records);
    client.authStore.clear();
  };

  useEffect(() => {
    fetchImages();
  }, []);

  return (
    <>
      <PhotoProvider>
        <div className="pictures-container">
          {images.map((img) => {
            return (
              <>
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  transition={transition}
                  className="pictures-list-item"
                  key={img.id}
                >
                  <PhotoView
                    key={img.id}
                    src={`${BASE_URL}/${img["@collectionId"]}/${img.id}/${img.image}`}
                  >
                    <motion.img
                      src={`${BASE_URL}/${img["@collectionId"]}/${img.id}/${img.image}`}
                      alt="Kaws Jacket"
                      loading="lazy"
                    />
                  </PhotoView>
                  <p>{img.image_description}</p>
                </motion.div>
              </>
            );
          })}
        </div>
      </PhotoProvider>
    </>
  );
}

export default Pictures;
