import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FaWindowClose } from "react-icons/fa";

function Modal({ showModal, setShowModal, techType, techDescription }) {
  return (
    <>
      <AnimatePresence exitBeforeEnter>
        {showModal && (
          <motion.div
            className="backdrop"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className="modal"
              initial={{ y: "-100vh" }}
              animate={{ y: 0 }}
            >
              <div className="modal-header">
                <p className="modal-tech-title">{techType}</p>
                <motion.button
                  className="close-modal-btn"
                  whileTap={{ scale: 0.9 }}
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  <FaWindowClose style={{ fill: "#2978b5" }} />
                </motion.button>
              </div>
              <div className="modal-body">
                <p>{techDescription}</p>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

export default Modal;
