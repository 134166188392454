import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Navbar = () => {
  return (
    <>
      <header className="header-center">
        <motion.h3 whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
          JN.
        </motion.h3>
        <nav className="center-nav">
          <ul className="nav-list">
            <motion.li whileHover={{ scale: 1.1, color: "#2978b5" }}>
              <a href="/">Home</a>
            </motion.li>

            <motion.li whileHover={{ scale: 1.1, color: "#2978b5" }}>
              <Link to="/about">About</Link>
            </motion.li>

            {/* <motion.li whileHover={{ scale: 1.1 }}>
              <a href="#projects">Projects</a>
            </motion.li> */}

            <motion.li whileHover={{ scale: 1.1 }}>
              <a href="/posts">Blog</a>
            </motion.li>
            <motion.li whileHover={{ scale: 1.1 }}>
              <a href="/portfolio">Portfolio</a>
            </motion.li>
          </ul>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
