import { React, useEffect, useState } from "react";
import PocketBase from "pocketbase";
import { FaLinkedin, FaGithub, FaInstagram, FaJava } from "react-icons/fa";
import { SiJavascript } from "react-icons/si";
import jordan from "../../assets/jordan_nelson_no_bg.png";
import javaLogo from "../../assets/java-svgrepo-com.svg";
import javaScriptLogo from "../../assets/javascript-svgrepo-com.svg";
import reactLogo from "../../assets/react-svgrepo-com.svg";
import cssLogo from "../../assets/css-svgrepo-com.svg";
import htmlLogo from "../../assets/html-svgrepo-com.svg";
import sqlLogo from "../../assets/mysql-svgrepo-com.svg";
import mongoLogo from "../../assets/mongo-svgrepo-com.svg";
import gitLogo from "../../assets/git-svgrepo-com.svg";

function About() {
  const [documents, setDocuments] = useState([]);
  const BASE_URL = "https://morning-bird-9561.fly.dev/api/files";
  let resumeURL = "";

  const fetchDocuments = async () => {
    const client = new PocketBase("https://morning-bird-9561.fly.dev");
    await client.admins.authViaEmail("nelsonjordy2@gmail.com", "Airkraft.00");
    const records = await client.records.getFullList(
      "personal_files",
      200 /* batch size */,
      {
        sort: "-created",
      }
    );
    setDocuments(records);
  };

  return (
    <>
      <div className="about-grid">
        <div className="left-grid-item">
          <div className="timeline-flex">
            <img src={jordan} alt="jordan nelson" className="testimonial-img" />
            <h3 className="timeline-item-title-left">Jordan Nelson</h3>
            <p className="testimonial-paragraph-left">
              Junior Software Developer
            </p>
            <ul className="social-links-left">
              <li className="social-link-item">
                <a
                  href="https://www.linkedin.com/in/jordy-nelson/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i>
                    <FaLinkedin />
                  </i>
                </a>
              </li>
              <li className="social-link-item">
                <a
                  href="https://github.com/jordynelson-2"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i>
                    <FaGithub />
                  </i>
                </a>
              </li>
              <li className="social-link-item">
                <a
                  href="https://www.instagram.com/tucks_shop"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i>
                    <FaInstagram />
                  </i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <article className="right-grid-item grid-col-span-2">
          <div className="timeline-flex">
            <h3 className="timeline-title">Timeline</h3>
            <p className="timeline-paragraph">
              Here's a brief timeline of my life events. If you want to know
              more about me as a professional programmer, see my{" "}
              <button
                className="resume-span"
                onClick={() => {
                  window.open("/Jordan_Nelson_Resume_23.pdf", "_blank");
                }}
              >
                Resume
              </button>
            </p>
          </div>
          <hr className="hr"></hr>
          <h3 className="timeline-title">Employment History</h3>
          <div className="timeline-flex">
            <h3 className="timeline-item-title">
              <span className="highlight-word">Junior Developer </span> at
              Alchemy Technology Services, Derry
            </h3>
            <h4 className="timeline-item-sub-title">
              November 2020 - May 2022
            </h4>
            <ul className="timeline-item-info-list">
              <li className="timeline-item-info-list-item">
                Providing technical insight during sprint planning sessions
              </li>
              <li className="timeline-item-info-list-item">
                Implemented and tested user stories working alongside BAs
              </li>
              <li className="timeline-item-info-list-item">
                Versioned code using Bitbucket
              </li>
              <li className="timeline-item-info-list-item">Code reviews</li>
              <li className="timeline-item-info-list-item">CI/CD</li>
              <li className="timeline-item-info-list-item">
                Demoed implemented solutions to clients at the end of each
                Sprint
              </li>
            </ul>
          </div>
          <div className="timeline-flex">
            <h3 className="timeline-item-title">
              <span className="highlight-word">Software Tester</span> at Gartan
              Technology Services, Letterkenny
            </h3>
            <h4 className="timeline-item-sub-title">
              September 2018 — May 2019
            </h4>
            <ul className="timeline-item-info-list">
              <li className="timeline-item-info-list-item">
                Assisted with test planning and design
              </li>
              <li className="timeline-item-info-list-item">
                Assessed software bugs and compiled findings along with
                suggested resolutions for development team members
              </li>
              <li className="timeline-item-info-list-item">
                Regression Testing and Functional Testing
              </li>
              <li className="timeline-item-info-list-item">
                Wrote and optimized test cases to maximize success of manual
                software testing with consistent, thorough approaches
              </li>
              <li className="timeline-item-info-list-item">
                Testing both Desktop and Mobile versions of Company Applications
              </li>
            </ul>
          </div>
          <hr className="hr"></hr>
          <h3 className="timeline-title">Education</h3>
          <div className="timeline-flex">
            <h3 className="timeline-item-title">
              <span className="highlight-word">BSc in Applied Computing</span>,
              Letterkenny Institute of Technology, Letterkenny
            </h3>
            <h4 className="timeline-item-sub-title">
              September 2014 — May 2018
            </h4>
            <ul className="timeline-item-info-list">
              <li className="timeline-item-info-list-item">
                Graduated with upper 2:1
              </li>
              <li className="timeline-item-info-list-item">
                <b>Project Title:</b> An investigation into how an Android
                application using Fitbit data can influence Lifestyle changes
              </li>
            </ul>
            <hr className="hr"></hr>
            <h3 className="timeline-title">Skills</h3>
            <ul className="skills-list">
              <li className="skills-list-item">
                <img
                  src={javaLogo}
                  alt="java logo"
                  className="skills-list-item-logo"
                />
              </li>
              <li className="skills-list-item">
                <img
                  src={javaScriptLogo}
                  alt="js logo"
                  className="skills-list-item-logo"
                />
              </li>
              <li className="skills-list-item">
                <img
                  src={reactLogo}
                  alt="react logo"
                  className="skills-list-item-logo"
                />
              </li>
              <li className="skills-list-item">
                <img
                  src={cssLogo}
                  alt="react logo"
                  className="skills-list-item-logo"
                />
              </li>
              <li className="skills-list-item">
                <img
                  src={htmlLogo}
                  alt="react logo"
                  className="skills-list-item-logo"
                />
              </li>
              <li className="skills-list-item">
                <img
                  src={sqlLogo}
                  alt="react logo"
                  className="skills-list-item-logo"
                />
              </li>
              <li className="skills-list-item">
                <img
                  src={mongoLogo}
                  alt="react logo"
                  className="skills-list-item-logo"
                />
              </li>
              <li className="skills-list-item">
                <img
                  src={gitLogo}
                  alt="react logo"
                  className="skills-list-item-logo"
                />
              </li>
            </ul>
          </div>
        </article>
      </div>
    </>
  );
}

export default About;
