import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import MDXComponents from "../../MDXComponents";

function BlogPost({ blogPosts }) {
  const { id } = useParams();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => setScreenWidth(window.innerWidth));
    console.log(screenWidth);
  }, [screenWidth]);

  return (
    <>
      <div className="post-container">
        <ReactMarkdown
          components={{
            p: MDXComponents.h1,
          }}
        >
          {id.replace(/-/g, " ")}
        </ReactMarkdown>
        {blogPosts.map((post) => {
          if (post.title.replace(/\s+/g, "-") === id) {
            return (
              <>
                <ReactMarkdown
                  components={{
                    h1: MDXComponents.h1,
                    h2: MDXComponents.h2,
                    h4: MDXComponents.h4,
                    p: MDXComponents.p,
                    a: MDXComponents.a,
                  }}
                >
                  {post.content}
                </ReactMarkdown>
              </>
            );
          }
        })}
      </div>
    </>
  );
}

export default BlogPost;
