const MDXComponents = {
  h1: (props) => <h1 style={{ fontSize: "3rem" }} {...props} />,
  h2: (props) => <h2 style={{ fontSize: "1.5rem" }} {...props} />,
  h4: (props) => (
    <h4
      style={{
        fontSize: "1.2rem",
        fontFamily: "Poppins, sans-serif",
        color: "#444",
      }}
      {...props}
    />
  ),
  p: (props) => (
    <p
      style={{
        fontSize: "1rem",
        fontFamily: "Poppins, sans-serif",
        color: "#444",
      }}
      {...props}
    />
  ),

  a: (props) => (
    <a
      style={{
        fontSize: "1rem",
        fontFamily: "Poppins, sans-serif",
        color: "#2978b5",
        textDecoration: "none",
      }}
      {...props}
    />
  ),
  small: (props) => (
    <small
      style={{
        fontSize: "0.8rem",
        fontFamily: "Poppins, sans-serif",
        color: "#444",
      }}
      {...props}
    />
  ),
};

export default MDXComponents;
