import React, { useState } from "react";
import Navbar from "./components/Navbar/Navbar";
import Main from "./components/Main/Main";
import Modal from "./components/Modal/Modal";
import Pictures from "./components/Pictures/Pictures";
import About from "./components/About/About";
import PostsList from "./components/Blog/PostsList";
import BlogPost from "./components/Blog/BlogPost";
import blogPosts from "./posts.json";
import Portfolio from "./components/Portfolio/Portfolio";

import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

function App() {
  const [showModal, setShowModal] = useState(false);
  const [techType, setTechType] = useState("");
  const [techDescription, setTechDescription] = useState("");

  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Main
                  setShowModal={setShowModal}
                  setTechType={setTechType}
                  setTechDescription={setTechDescription}
                />
                <Modal
                  showModal={showModal}
                  setShowModal={setShowModal}
                  techType={techType}
                  techDescription={techDescription}
                />
              </>
            }
          />
          <Route path="/pictures" element={<Pictures />} />
          <Route
            path="*"
            element={
              <div>
                <h1>404: Not Found</h1>
                <Link to="/">
                  <button>Return home</button>
                </Link>
              </div>
            }
          />
          <Route path="/about" element={<About />} />
          <Route path="/posts" element={<PostsList />} />
          <Route
            path="/posts/:id"
            element={<BlogPost blogPosts={blogPosts} />}
          />
          <Route path="/portfolio" element={<Portfolio />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
