import React from "react";
import ReactMarkdown from "react-markdown";
import blogPosts from "../../posts.json";
import { Link } from "react-router-dom";
import MDXComponents from "../../MDXComponents";

function postsList() {
  console.log(blogPosts);
  return (
    <>
      <section className="posts">
        <h2>All Posts</h2>
        {blogPosts.map((post) => {
          return (
            <>
              <div key={post.id} className="posts-item">
                <Link to={post.title.replace(/\s+/g, "-")}>
                  <h1>{post.title}</h1>
                  <ReactMarkdown
                    components={{
                      p: MDXComponents.small,
                    }}
                  >
                    {post.author}
                  </ReactMarkdown>
                  <ReactMarkdown
                    components={{
                      p: MDXComponents.small,
                    }}
                  >
                    {post.date}
                  </ReactMarkdown>
                </Link>
              </div>
            </>
          );
        })}
      </section>
    </>
  );
}

export default postsList;
